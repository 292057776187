// import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from '@aws-amplify/core';

const oauth = {
  clientId: environment.cognito.clientId,
  domain: environment.cognito.domain,
  scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
  redirectSignIn: environment.cognito.redirectSignInUrl,
  redirectSignOut: environment.cognito.redirectSignOutUrl,
  responseType: 'code'
};

const amplifyConfig = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: environment.cognito.userPoolId,
  aws_user_pools_web_client_id: environment.cognito.clientId,
  oauth
};

Amplify.configure(amplifyConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
