<div mat-dialog-title oneDialogTitle>Select your location</div>
<div mat-dialog-content oneDialogContent>
  <mat-form-field appearance="fill" oneEditableDropdown>
    <mat-label>Location</mat-label>
    <input #triggerAffiliate="matAutocompleteTrigger" [formControl]="affiliateControl" [matAutocomplete]="countryName" matInput placeholder="Start typing or select" type="text" />
    <button (click)="togglePanel($event, countryName.isOpen, triggerAffiliate, 'FillBig')" [disabled]="formDisabled" mat-flat-button matSuffix one-icon-button-flat>
      <mat-icon>{{ countryName.isOpen ? 'collapse' : 'expand' }}</mat-icon>
    </button>
    <mat-autocomplete #countryName="matAutocomplete">
      <mat-option *ngFor="let affiliateLabel of affiliateFilterOptions | async" [value]="affiliateLabel">
        {{ affiliateLabel }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button (click)="onContinueClicked()" [disabled]="!affiliateControl.value || !isValid(affiliateControl.value)" class="continue-button" color="primary" mat-raised-button>
    Continue
  </button>
</div>
<div mat-dialog-actions oneDialogActions>
  <mat-checkbox [formControl]="rememberLocationControl">Remember my location </mat-checkbox>
</div>
